.selectContainer {
  @apply relative;
}

.selectContainerOpen {
  & [class$='control'] {
    & svg {
      @apply transform rotate-180 text-stone-500;
    }

    box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
  }
}

.select {
  & [class$='control'] {
    @apply h-56 items-end pb-6 hover:border-stone-500;

    & [class$='value-container'] {
      @apply p-0;
    }

    & svg {
      @apply transition-transform w-24 h-24 fill-current mb-[-8px];
      transition-duration: 0.4s;
    }
  }
}

.label {
  @apply absolute transform-gpu transition-all left-12 top-[18px] pointer-events-none;
}

.labelActive {
  @apply paragraph-12 font-semibold;
  transform: translatey(-8px);
}
